import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './login.css'
import logo from '../../Assets/images/logo.png';
import bookIcon from '../../Assets/images/book.png';
import userIcon from '../../Assets/images/group.png';
import ebookIcon from '../../Assets/images/e-book.png';
import qPaperIcon from '../../Assets/images/question.png';
import playstoreIcon from '../../Assets/images/playstoreIcon.png'

import { MyLoginModal1 } from './LoginModal';
import { ForgotModal1 } from './ForgotModal';
import { MyRegModal1 } from './RegistrationModal';

//redux
import { useDispatch } from 'react-redux';
import { userLogin } from '../../ReduxStore/Action/LoginAction';

// APIURL
import { userLoginAPI } from '../../Services/LoginAPIURL';
import { forgotPasswordAPI } from '../../Services/PasswordAPIURL';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { indexRoutesAPI } from '../../Services/HomeAPIURL';
//Loader
import Loader from '../CommonComponents/Loader/Loader';


const Login = ({ setIsUser }) => {

     //========================================
     const handleCancelMembershipClick = () => {
        navigate('/cancel-member-page');
        setShowCancelMemberModal(false);
      };
    //========================================
    const handlePrivacyPolicyClick = () => {
        navigate('/privacy');
        setShowCancelMemberModal(false);
      };
//=================================================================================

    const [showCancelMemberModal, setShowCancelMemberModal] = useState(true);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loaderOption, setLoaderOption] = useState(false);

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegModal, setShowRegModal] = useState(false);
    const [showForgotModal, setShowForgotModal] = useState(false);

    const [countData, setCountData] = useState([]);

    //Form Variables
    const [loginId, setLoginId] = useState("");
    const [password, setPassword] = useState("");
    const [instituteId, setInstituteId] = useState(1);  //For single institute default value is 1
    const [email, setEmail] = useState("");

    const getData = async () => {
        await axios.get(indexRoutesAPI)
            .then((res) => {
                if (res.data === "ERROR") {
                    console.log("Error Occured")
                }
                else {
                    setCountData(res.data)
                }
            })
    }
    useEffect(() => {
        getData();
    }, [])

    const LoginFun = async (e) => {
        setLoaderOption(true);
        e.preventDefault();

        if (loginId !== "" && password !== "") {

            const loginObject = {
                instituteId: instituteId,
                loginId: loginId,
                password: password
            }

            await axios.post(userLoginAPI, loginObject)
                .then((res) => {
                    if (res.data === "PENDING") {
                        setLoaderOption(false);
                        toast.error("Your Account Is Not Approved, Contact To Admin.")
                    }
                    else if (res.data === "BLOCK") {
                        setLoaderOption(false);
                        toast.error("Your Account Is Blocked, Contact To Admin.")
                    }
                    else if (res.data === "FAILED") {
                        setLoaderOption(false);
                        toast.error("You have entered wrong credentials..")
                    }
                    else {
                        const logObject = {
                            "member_name": res.data[0].member_name,
                            "member_type": res.data[0].member_type,
                            "institute_id": res.data[0].institute_id,
                            "id": res.data[0].id,
                        }
                        sessionStorage.setItem("digital-library", JSON.stringify(logObject));
                        dispatch(userLogin(logObject));
                        setIsUser(true);
                        navigate("/");
                    }


                })
                .catch((err) => {
                    setLoaderOption(false);
                    toast.error("Network error. Try after sometime.")
                    console.log("err :" + err)
                })


        }
        else {
            setLoaderOption(false);
            toast.error("Fields must not be empty");
        }

    }

    const ForgotFun = async (e) => {
        setLoaderOption(true);
        e.preventDefault()
        // alert(email)
        const sendObj = {
            "email": email
        }

        await axios.post(forgotPasswordAPI, sendObj)
            .then((res) => {
                if (res.data === "NOTMATCH") {
                    setLoaderOption(false);
                    toast.warn("Wrong Email Id.")
                }
                else if (res.data === "SENT") {
                    clearFun();
                    setShowForgotModal(false);
                    setLoaderOption(false);
                    toast.success("Your password has been sent to your email id.")
                }
                else if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error occured, Something went wrong.")
                }
                else {
                    setLoaderOption(false);
                    console.log("Error : " + res.data);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log("Error :" + err);
            })

    }


    const clearFun = () => {
        setLoginId("");
        setPassword("");
        setEmail("");
    }

    return (
        <>
            {(loaderOption) ? <Loader /> : null}
            {/* <body> */}

                <div>

                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>

            {/* </body> */}
            {showCancelMemberModal && (
            <div className="container mt-4">
                <div className="col-12 text-center">
                    <img src={logo} style={{ width: 150 }} alt="Logo" />
                </div>
                <div className="col-12 text-center mt-4">
                    <h5 className='headTitle'>Shikshan Prasarak Mandal's</h5>
                    <h1 className='headTitle'>Gopal Krishna Gokhale College, Kolhapur</h1>
                    <h4 className='headTitle'>Shikshan Maharshi Prin. M. R. Desai Library</h4>

                </div>

                <div className="card-container">
                    <div className='cards text-center'>
                        <img src={bookIcon} alt="Book Icon" /><br />
                        <strong>BOOKS</strong>
                        <h5>
                            {
                                (countData.length > 0) ? countData[0].books : "000"
                            }
                        </h5>

                    </div>

                    <div className='cards text-center'>
                        <img src={userIcon} alt="User Icon" /> <br />
                        <strong>USERS</strong>
                        <h5>
                            {
                                (countData.length > 0) ? countData[0].user : "000"
                            }
                        </h5>
                    </div>

                    <div className='cards text-center'>
                        <img src={ebookIcon} alt="E-Book Icon" /><br />
                        <strong>E-BOOKS</strong>
                        <h5>
                            {
                                (countData.length > 0) ? countData[0].ebooks : "000"
                            }
                        </h5>
                    </div>

                    <div className='cards text-center'>
                        <img src={qPaperIcon} alt="Que-Paper Icon" /><br />
                        <strong>QUESTION-PAPER</strong><br />
                        <h5>
                            {
                                (countData.length > 0) ? countData[0].qpaper : "000"
                            }
                        </h5>
                    </div>
                </div>

                <div className="card-container mt-4">
                    <span className='loginBtn' onClick={() => setShowLoginModal(true)}>LOGIN</span>
                    <span className='regBtn' onClick={() => setShowRegModal(true)}>REGISTER</span>

                    <span className='regBtn'   onClick={handleCancelMembershipClick}>CANCLE MEMBERSHIP</span>
                    <span className='regBtn'   onClick={handlePrivacyPolicyClick}>PRIVACY POLICY</span>
                </div>

            </div>
            )}


            <div className="footer">

                <div className="col-6 fc1">
                    © GKG College Library 2022-23 | Managed by: <a href="https://technoaarv.com/" target="_blank">TechnoAarv Solution</a>
                </div>
                <div className="col-6 fc2">
                    <a href="https://play.google.com/store/apps/details?id=com.gkg.digitallibrarysystem&hl=en" target="_blank">
                        <img src={playstoreIcon} alt="Playstore Icon" />
                    </a>
                </div>

            </div>

            <MyLoginModal1
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
                loginId={loginId}
                setLoginId={setLoginId}
                password={password}
                setPassword={setPassword}
                LoginFun={LoginFun}
                clearFun={clearFun}
                setShowForgotModal={setShowForgotModal}
                setShowRegModal={setShowRegModal}
            />

            <MyRegModal1
                showRegModal={showRegModal}
                setShowRegModal={setShowRegModal}
                setShowLoginModal={setShowLoginModal}
            />

            <ForgotModal1

                showForgotModal={showForgotModal}
                setShowForgotModal={setShowForgotModal}
                email={email}
                setEmail={setEmail}
                clearFun={clearFun}
                ForgotFun={ForgotFun}
            />

            <ToastContainer theme='dark' position='top-right' />

        </>

    )
}

export default Login