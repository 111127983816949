import React, { useState, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner';

//Redux
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';

import { webOpacAPI } from '../../Services/WebOpacAPIURL';

import Loader from '../CommonComponents/Loader/Loader';

const WebOpac = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "WEB OPAC" }))
    }, [])

    const [loaderOption, setLoaderOption] = useState(false)
    const [spinnerOption, setSpinnerOption] = useState(false)

    const [ofset, setOfset] = useState(0);
    const [limit, setLimit] = useState(5000);
    const [calNum, setCalNum] = useState(0);

    // const classes = useStyles();
    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = [
        { label: 'Acc.No', key: 'id' },
        { label: `Acc.Type`, key: 'acc_type_name' },
        { label: `Title`, key: 'title' },
        { label: `Author`, key: 'author' },
        { label: `Publisher`, key: 'publisher' },
        { label: `Status`, key: 'status' },
        { label: `Call No`, key: 'call_no' },
        // { label: 'Edit', key: 'edit', value: 'edit', isAction: true },
        // { label: 'Delete', key: 'del', value: 'delete', isAction: true }
    ]

    const [mainData, setMainData] = useState([]);
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }
    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.publisher.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.acc_type_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.call_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const getData = async () => {
        setSpinnerOption(true)
        await axios.post(webOpacAPI + authUser.institute_id + "/" + ofset + "/" + limit)
            .then((res) => {
                //console.log(res);
                if (res.data === "ERROR") {
                    toast.error("Server Error, Try again after sometime.")
                    setSpinnerOption(false)
                }
                else {
                    setMainData(mainData.concat(res.data));
                    setData(data.concat(res.data));
                    setFilteredData(filteredData.concat(res.data))

                    if (res.data.length === limit) {
                        setOfset(ofset + limit);
                        setCalNum(calNum + 1);
                    }
                    else {
                        setSpinnerOption(false)
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setLoaderOption(false)
            })

    }

    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser, calNum])

    const dataSetting = (num) => {
        if (num === 0) {
            setFilteredData([].concat(mainData));
            setData(([].concat(mainData)))
        }
        else {
            let filterTempData = mainData.filter((e) => e.status == num);
            setFilteredData([].concat(filterTempData));
            setData(([].concat(filterTempData)));
        }
    }


    return (
        <div>

            {(loaderOption) ? <Loader /> : null}

            <div className='row'>
                {
                    (spinnerOption) ?

                        <div className='text-center'>
                            <Spinner animation="grow" size='sm' className='mx-1' variant="primary" />
                            <Spinner animation="grow" size='sm' className='mx-1' variant="secondary" />
                            <Spinner animation="grow" size='sm' className='mx-1' variant="success" />
                            <Spinner animation="grow" size='sm' className='mx-1' variant="danger" />
                            <Spinner animation="grow" size='sm' className='mx-1' variant="warning" />
                            <Spinner animation="grow" size='sm' className='mx-1' variant="info" />
                            <Spinner animation="grow" size='sm' className='mx-1' variant="dark" />
                        </div>


                        :
                        <div>
                            <span className='btn btn-sm btn-primary mx-1'
                                onClick={() => dataSetting(0)}
                            >
                                All <Badge bg="light" text="dark"> {mainData.length}</Badge>
                            </span>
                            <span className='btn btn-sm btn-success mx-1'
                                onClick={() => dataSetting(1)}
                            >
                                Available <Badge bg="light" text="dark"> {mainData.filter((e) => e.status == 1).length}</Badge>
                            </span>

                            <span className='btn btn-sm btn-info mx-1'
                                onClick={() => dataSetting(2)}
                            >
                                Issued <Badge bg="light" text="dark"> {mainData.filter((e) => e.status == 2).length}</Badge>
                            </span>

                            <span className='btn btn-sm btn-warning mx-1'
                                onClick={() => dataSetting(3)}
                            >
                                Book Bank <Badge bg="light" text="dark"> {mainData.filter((e) => e.status == 3).length}</Badge>
                            </span>

                            <span className='btn btn-sm btn-secondary mx-1'
                                onClick={() => dataSetting(5)}
                            >
                                Binding <Badge bg="light" text="dark"> {mainData.filter((e) => e.status == 5).length}</Badge>
                            </span>

                            <span className='btn btn-sm btn-danger mx-1'
                                onClick={() => dataSetting(4)}
                            >
                                Withdrawl <Badge bg="light" text="dark"> {mainData.filter((e) => e.status == 4).length}</Badge>
                            </span>
                        </div>
                }

                <div>
                    <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setSearchTerm(event.target.value); }}
                    />
                </div>
            </div>

            
            <div className="table-responsive mt-2">

                <table className="table table-bordered">
                    <thead className="table-Default">
                        <tr style={{ backgroundColor: "#F5E3E6", backgroundImage: "linear-gradient(200deg, #F5E3E6 10%, #D9E4F5 100%)" }}>
                            {TableHeading.map((item, ind) => {
                                return (
                                    <th
                                        style={{ fontSize: "12px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                        onClick={() => onSort(item.key, item.isAction)}
                                        key={ind}
                                        scope="col"
                                    >
                                        {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{ borderBottom: "2px solid #D8EDF7" }}>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ fontSize: "14px", fontWeight: "500", }}>{item.id}</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.acc_type_name}</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.title}</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.author}</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.publisher}</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>

                                            {(() => {
                                                if (item.status == 1) {
                                                    return <Badge bg="success">Available</Badge>
                                                }
                                                else if (item.status == 2) {
                                                    return <Badge bg="info" text="dark">Issued</Badge>
                                                }
                                                else if (item.status == 3) {
                                                    return <Badge bg="warning" text="dark">Book Bank</Badge>
                                                }
                                                else if (item.status == 5) {
                                                    return <Badge bg="secondary">Binding</Badge>
                                                }
                                                else {
                                                    return <Badge bg="danger">Withdrawal</Badge>
                                                }
                                            })()}
                                        </td>
                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.call_no}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                    <tfoot >
                        <tr style={{ padding: "4px" }}>
                            <TablePagination
                                style={{ padding: 0, borderBottom: "0px" }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                labelRowsPerPage=" Rows : "
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                                onPageChange={onPageChange}
                                //classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                            />
                        </tr>
                    </tfoot>
                </table>
            </div>
            <ToastContainer theme='dark' position='top-right' />
        </div>
    )
}

export default WebOpac