import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const ForgotModal = (props) => {

    return (
        <>
            <Modal
                show={props.showForgotModal}
                onHide={() => { props.setShowForgotModal(false); props.clearFun() }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className='modalHead'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Forgot Password
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={props.ForgotFun}>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email Id" required
                                value={props.email}
                                onChange={(e) => props.setEmail(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Button type='submit' size='sm' variant="primary">SUBMIT</Button>
                        <Button onClick={() => { props.setShowForgotModal(false); props.clearFun() }} size='sm' style={{ marginLeft: "8px" }} variant='danger'>CLOSE</Button>
                    </Modal.Body>
                </Form>


            </Modal>

      

        </>
    )
}

export const ForgotModal1 = React.memo(ForgotModal)