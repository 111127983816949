import React, { useState, useEffect } from 'react'
import Login from './Components/LoginComponents/Login'
import MainComponent from './Components/MainComponent/MainComponent'
import {  Route, Routes, useLocation,useNavigate } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
import { userLogin } from './ReduxStore/Action/LoginAction';
import CancelRegistration from './Components/LoginComponents/CancelRegistration';
import PrivacyPolicy from './Components/LoginComponents/PrivacyPolicy';
import { ForgotModal1 } from './Components/LoginComponents/ForgotModal';

const App = () => {

  const dispatch = useDispatch();

  const [isUser, setIsUser] = useState(false);

  const checkLogin = () => {
    const logData = sessionStorage.getItem("digital-library")
    //console.log(logData)


    if (logData) {
      const convLogData = JSON.parse(logData)
      // console.log(convLogData.loginId + convLogData.password)
      const dispatchLogData = {
        loginId: convLogData.loginId,
        password: convLogData.password
      }
      dispatch(userLogin(dispatchLogData))
      setIsUser(true)
    }
    else {
      setIsUser(false)
    }
  }

  useEffect(() => {
    checkLogin();
  }, [])


  return (
    <>

<Routes>

<Route path="/cancel-member-page" element={<CancelRegistration />} />

<Route path="/privacy" element={<PrivacyPolicy />} />


</Routes>

    
{isUser ? (
        <MainComponent setIsUser={setIsUser} />
      ) : (
        <Login setIsUser={setIsUser} /> 
      )}
</>
  )
}

export default App