import React from 'react'
import "./footer.css"
import technoAarvLogo from "../../Assets/images/technoAarvLogo.png";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-md-8 mt-2">
                        <strong>Disclaimer:</strong>
                        <p>
                            This site is designed and hosted by <a style={{ color: "#fff" }} href="https://technoaarv.com/" target="_blank" rel="noopener noreferrer">TechnoAarv Solution</a>,
                            The contents are provided by <b>Gopal Krishna Gokhale College, Kolhapur. </b> 
                            For any further information, please contact <b>Gopal Krishna Gokhale College, Kolhapur.</b>.
                        </p>
                    </div>

                    <div className="col-sm-4 col-md4 my-2 text-left">
                        <img src={technoAarvLogo} alt="" className='img-fluid mt-2'
                            style={{ height: "35px" }}
                        />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer