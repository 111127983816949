import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


const LoginModal = (props) => {

    return (
        <>
            <Modal
                show={props.showLoginModal}
                onHide={() => { props.setShowLoginModal(false); props.clearFun() }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='modalHead'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        LOGIN
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={props.LoginFun}>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Login ID</Form.Label>
                            <Form.Control type="text" placeholder="Enter Login ID" required
                                value={props.loginId}
                                onChange={(e) => props.setLoginId(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                We'll never share your credentials with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" required
                                value={props.password}
                                onChange={(e) => props.setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button type='submit' size='sm' variant="primary">LOGIN</Button>
                        <Button onClick={() => { props.setShowLoginModal(false); props.clearFun() }} size='sm' style={{ marginLeft: "8px" }} variant='danger'>CLOSE</Button>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button type='submit' size='sm' variant="primary">LOGIN</Button>
                        <Button onClick={props.onHide} size='sm' variant='danger'>CLOSE</Button>
                    </Modal.Footer> */}

                    <div style={{ display: "flex", padding: "10px", fontSize: "12px", fontWeight: "600" }}>
                        <div className="col-6 text-primary" style={{ cursor: "pointer" }}>
                            <span
                                onClick={() => {
                                    props.setShowLoginModal(false)
                                    props.setShowForgotModal(true);
                                }}
                              
                            >
                                Forget Password ?</span>
                        </div>
                        <div className="col-6 text-primary" style={{ textAlign: "right", cursor: "pointer" }}>
                            <span
                                onClick={() => {
                                    props.setShowLoginModal(false)
                                    props.setShowRegModal(true);

                                }}
                            >Create New Account</span>
                        </div>
                    </div>
                </Form>

            </Modal>
        </>
    )
}

export const MyLoginModal1 = React.memo(LoginModal) 